
/* Source: src/mt-includes/assets/jquery-plugins/moto-absolute-cover/moto-absolute-cover.js*/
(function($) {
    var FILL_BY_HEIGHT_CLASSNAME = 'moto-absolute-cover-element_fill-by-height';
    var CALCULATING_CLASSNAME = 'moto-absolute-cover-element_calculating';

    function updateElement($childElement, $parentElement) {
        var aspectRatioContainer;
        var aspectRatioElement;

        $childElement.addClass(CALCULATING_CLASSNAME);
        $childElement.removeClass(FILL_BY_HEIGHT_CLASSNAME);

        aspectRatioContainer = $parentElement.outerWidth() / $parentElement.outerHeight();
        aspectRatioElement = $childElement.outerWidth() / $childElement.outerHeight();

        if (aspectRatioContainer <= aspectRatioElement) {
            $childElement.addClass(FILL_BY_HEIGHT_CLASSNAME);
        }
        $childElement.removeClass(CALCULATING_CLASSNAME);
    }

    $.fn.motoAbsolutePositionCover = function($childElement) {
        var $parentElement = this;
        var childElement;

        if (!$childElement) {
            return this;
        }

        childElement = $childElement.get(0);

        if (!childElement) {
            return this;
        }

        // for not loaded images
        if (angular.isDefined(childElement.complete) && !childElement.complete) {
            $childElement.one('load', function() {
                updateElement($childElement, $parentElement);
            });

            return this;
        }

        // for not loaded videos
        if (angular.isDefined(childElement.readyState) && childElement.readyState <= 0) {
            $childElement.one('loadedmetadata', function() {
                updateElement($childElement, $parentElement);
            });

            return this;
        }

        updateElement($childElement, $parentElement);

        return this;
    };
})(jQuery);