
/* Source: src/mt-includes/assets/jquery-plugins/video-background/video-background.js*/
(function($) {
    var $elements = [];

    function checkAspectRatios($element) {
        var $videoElement = $element.find('video');

        $element.motoAbsolutePositionCover($videoElement);
    };

    $(window).on('resize', function() {
        $elements.forEach(checkAspectRatios);
    });

    $.fn.motoVideoBackground = function() {
        var $element = this;
        var isElementExistsAlready = false;

        $elements.forEach(function(element) {
            if ($element.is(element)) {
                isElementExistsAlready = true;
            }
        });

        if (!isElementExistsAlready) {
            $elements.push($element);
        }

        checkAspectRatios($element);
    };
})(jQuery);